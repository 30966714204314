#splash {
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
