#advert {
    & #discover {
        & .text {
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 600;
            color: #000;
            text-transform: capitalize;

            @media (min-width: 768px) {
                font-size: 18px;
            }
        }
        padding: 12px 50px;
    }
    @media (min-width: 768px) {
        & #discover {
            padding: 22px 80px;
        }
    }
}
