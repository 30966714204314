#wordle {
    padding: 4px;
    overflow: hidden;

    &-screen {
        display: block;
        flex: auto;
        position: relative;

        &-content {
            position: absolute;       
            left: 50%;
            transform: translateX(-50%);
        }

        & #adwert {
            display: flex;
            align-items: center;
            gap: 11px;
            padding: 12px 20px;
            border-radius: 10px;

            &-slogan {
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        & #wordle-timer {
            display: flex;
            justify-content: space-between;

            &-header {
                font-size: 14px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-time {
                display: flex;
                align-items: center;
                gap: 2px;

                & .timer-divider {
                    margin-top: -4px;
                }

                & p {
                    font-size: 20px;
                    font-weight: 500;
                }

                & .wordle-timer-time-part {
                    width: 25px;
                    text-align: center;
                }
            }
        }

        & #sponsor {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 11px;
            padding: 12px 20px;
            border-radius: 10px;

            & hr {
                height: 12px;
                align-self: center;
            }

            &-text {
                text-transform: uppercase;
            }
        }

        & #keyboard {
            &-row {
                gap: 6.36px;
                margin: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-key {
                display: flex;
                flex: 1;
                padding: 12px 0;
                min-width: auto;
                border-radius: 5.45px;

                &.button-key {
                    flex: 1.5;
                }

                &-text {
                    display: flex;
                    font-weight: 600;
                }
            }
        }

        & #setting-dialog-box,
        & #statistic-dialog-box {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 0 30px;
        }

        & #wordle {
            display: flex;
            gap: 5px;
            flex-direction: column;
            margin: 0 auto;

            & .wordle {
                &-row {
                    display: flex;
                    justify-content: center;
                    gap: 5px;

                    &-character {
                        border: 1px solid;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 600;
                        border-radius: 10.274px;

                        &-box {
                            display: flex;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

#setting-dialog {
    & .MuiDialog-paper {
        overflow-y: unset;
        user-select: none;
        padding: 15px 0;
    }

    & .MuiDialogTitle-root {
        text-align: center;
    }

    &-content {
        padding: 23px 27px 0px;

        & .MuiGrid-root.MuiGrid-container {
            & .setting-dialog-login-block {
                display: flex;
                gap: 13px;
                padding-top: 0 !important;
            }

            & .setting-dialog-item-block {
                display: flex;
                flex-direction: column;
                gap: 14px;
            }
        }

        & .slick-dots {
            position: initial;
            margin: 10px 0;
        }
    }

    & .setting-dialog {
        &-title {
            font-size: 14px;
            font-weight: bold;
        }

        &-close-box {
            position: absolute;
            top: -63px;
            left: 50%;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-50%);
            border: 1px solid rgba(163, 169, 176, 0.05);
        }
    }
}

#statistic-dialog {
    & .MuiDialog-paper {
        overflow-y: unset;
        user-select: none;
        border-radius: 20px;
        max-height: calc(100% - 80px);
        padding: 15px 0;
        margin: 90px 5px 32px;
        width: calc(100% - 10px);
    }

    & .statistic-dialog {
        &-title {
            font-size: 20px;
            font-weight: 800;
        }

        &-share {
            font-size: 14px;
            font-weight: 600;
        }

        &-close-box {
            position: absolute;
            top: -63px;
            left: 50%;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-50%);
            border: 1px solid rgba(163, 169, 176, 0.05);
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 14px;

        & #statistic-tab {
            border-radius: 30px;
            padding: 4px;
            overflow: initial;
            height: 40px;
            min-height: auto;

            & .MuiTabs-scroller {
                height: max-content;
            }

            & .MuiTabs-indicator {
                display: none;
            }

            & .MuiTabs-flexContainer {
                height: 32px;
            }

            & button {
                text-transform: none;
                font-weight: 700;
            }

            & .statistic-tab-item {
                font-size: 12px;
                font-weight: 600;
                width: 50%;
                color: #b3bac1;
                min-height: 32px;
                height: 32px;

                &.Mui-selected {
                    color: #ffffff;
                    border-radius: 30px;
                }
            }
        }

        & #statistic-data {
            & .statistic-data-item {
                padding: 10.75px;
                border-radius: 8px;
                height: 100%;

                &-title {
                    font-size: 8px;
                    font-weight: 600;
                }

                &-result {
                    font-size: 20px;
                    font-weight: 700;
                }

                &-change {
                    font-size: 9.483px;
                    font-weight: 600;

                    &-desc {
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }
        }

        & #prediction-distribution {
            &-title {
                font-size: 14px;
                font-weight: 600;
            }

            &-content {
                display: flex;
                flex-direction: column;
                gap: 4px;
                margin-top: 16px;
            }

            & .prediction-distribution-content-item {
                display: flex;
                align-items: center;
                gap: 5.2px;

                &-title {
                    font-size: 9.659px;
                    width: 10px;
                    font-weight: 500;
                }

                &-content {
                    width: 0%;
                    min-width: 20px;
                    height: 19px;
                    border-radius: 4px;
                    padding-right: 8px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    transition: width 1s;

                    &-title {
                        font-size: 9.659px;
                        font-weight: 700;
                        color: #ffffff;
                    }
                }
            }
        }

        & #game-summary-adwert {
            height: 88px;
            display: flex;
            gap: 19px;
            border-radius: 12px;

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;

                & img {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }

                &-brand {
                    color: #0b5ed7;
                }

                &-slogan {
                    font-size: 10px;
                    font-weight: 600;
                }
            }

            &-arrow {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-right: 19px;
            }
        }

        & #point-table {
            display: flex;
            flex-direction: column;
            gap: 17px;
            margin-top: 20px;

            &-header {
                display: flex;
                justify-content: space-between;

                &-title {
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 5px;
                }

                &-date {
                    color: #b3bac1;
                    font-size: 14px;
                    font-weight: 600;
                    margin-right: 13px;
                }
            }

            &-leaderboard {
                position: relative;

                &-after {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    overflow-y: auto;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 90px;
                        bottom: 0;
                        left: 0;
                    }
                }

                & .point-table-item {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 30px;
                    padding: 5px;
                    border-color: transparent;

                    &-time {
                        margin-right: 10px;
                        font-size: 14px;
                        font-weight: 800;
                    }

                    &-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex: 1;

                        &-info {
                            display: flex;
                            gap: 8px;
                            align-items: center;

                            &-placement {
                                font-weight: 700;
                                color: #b3bac1;
                            }

                            &-divider {
                                height: 10px;
                                align-self: center;
                            }

                            &-username {
                                font-weight: 700;
                            }
                        }
                    }

                    &-avatar {
                        &-badge {
                            width: 22px;
                            height: 22px;
                            border-radius: 50%;
                            border: 2px solid #ffffff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        &-name {
                            font-size: 14px;
                            font-weight: 600;
                            color: #ffffff;
                        }
                    }
                }
            }

            &-not-authenticate {
                padding: 30px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                &-btn {
                    margin-top: 10px;
                    text-transform: initial;
                    border-radius: 30px;
                    box-shadow: none;
                    font-size: 16px;
                    color: #ffffff;
                }

                & .point-table-not-authenticate {
                    &-title {
                        font-size: 28px;
                        font-weight: 700;
                        letter-spacing: -0.5px;
                        line-height: 30px;
                    }

                    &-desc {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.2;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

#share-menu {
    & .MuiPaper-root {
        border-radius: 16px;

        & .MuiList-root {
            padding: 0 !important;

            & .share-button {
                display: flex;
                flex: 1;
                justify-content: space-between;
                gap: 30px;

                &-text {
                    font-weight: 600;
                }
            }

            & hr {
                margin: 0 !important;
            }
        }

        & .MuiMenuItem-root {
            padding: 0px !important;
        }
    }
}

#notification-dialog {
    z-index: 1400;

    & .MuiDialog-container {
        position: relative;
        top: 20%;
        height: auto;
        user-select: none;
    }

    & .MuiDialog-paper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 17px 25px;
        border-radius: 46px;
        background: rgba(10, 14, 18, 0.8);
        backdrop-filter: blur(17px);
        border: 1px solid #31ac37;

        & .MuiDialogContent-root {
            padding: 0;

            &.content {
                display: flex;
                align-items: center;
                gap: 5px;

                & .content-text {
                    color: #fff;
                    font-size: 15px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    display: flex;
                    align-items: baseline;
                    gap: 5px;
                    text-align: center;
                }

                & .content-icon {
                    font-size: 17px;
                }
            }
        }
    }
}
