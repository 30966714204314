#header {
    width: 100%;
    padding: 5px;
    display: flex;

    & .menu-icons {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 14px;

        & #other-icons {
            display: flex;
            gap: 14px;
        }

        & [id$="-icon"] {
            cursor: pointer;
        }
    }

    &-timer {
        #wordle-timer-time {
            display: flex;
            align-items: center;

            & .timer-divider {
                margin-top: -4px;
            }

            & p {
                font-size: 16px;
                font-weight: 500;
            }

            & .wordle-timer-time-part {
                width: 25px;
                text-align: center;
            }
        }
    }
}
